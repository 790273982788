<!-- 首页 -->
<template>
  <div>
    <div class="banner" id="box1">
      <div class="horizontalline">
        <div class="mod2">
          <span class="word3">创既成就&nbsp;简则易从</span>
          <span class="infoBox1">
            <br />以人工智能、大数据和物联网等技术为企业提供全方位碳中和数字化转型解决方案
          </span>
        </div>
        <div
          class="pullDown"
          style="
            width: 150px;
            height: 3px;
            background: #ffffff;
            margin-top: 20px;
          "
        ></div>
        <div class="mod3" @click="click(0)">
          <span class="word">申请试用</span>
        </div>
        <span class="word4">
          向下滚动
          <div class="rightan" @click="but">
            <img class="icon1" referrerpolicy="no-referrer" src="../../assets/icon.png" />
          </div>
        </span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="layer6 flex-col" id="box2">
      <div class="wrap2 flex-row justify-between">
        <div class="wrap3">
          <span class="info3">
            <span class="small"></span>
            {{ data[shuangtan].name }}
            <span class="small smalls"></span>
          </span>
          <div class="outer1"></div>
          <span class="word5">{{ data[shuangtan].con }}</span>
          <button class="outer2 l">
            <span class="word6" @click="go(shuangtan)">申请试用</span>
          </button>
        </div>
        <div class="wrap4">
          <img class="img2" referrerpolicy="no-referrer" :src="data[shuangtan].img" />
          <div class="layer7"></div>
        </div>
      </div>

      <div class="wrap5 flex-row">
        <div class="box1">
          <img class="img3" referrerpolicy="no-referrer" :src="data[esg].img" />
        </div>
        <div class="box2 justify-between">
          <span class="word7">
            <span class="small"></span>
            {{ data[esg].name }}
            <span class="small smalls"></span>
          </span>
          <div class="mod5"></div>
          <!-- <span :class="data[esg].con ? 'word5' : ''">{{ data[esg].con }}</span> -->
          <span class="word5">{{ data[esg].con }}</span>
          <div class="phone">
            <img class="img3" referrerpolicy="no-referrer" :src="data[esg].img" />
          </div>
          <button class="outer2">
            <span class="word6" @click="go(esg, 1)">申请试用</span>
          </button>
        </div>

        <div class="pic1">
          <div>
            <i class="el-icon-arrow-left" @click="exchange"></i>
          </div>
          <div>
            <i class="el-icon-arrow-right" @click="exchange"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shuangtan from "../../assets/shuangtan.png";
import ESGs from "../../assets/ESG.png";
export default {
  data () {
    return {
      data: [
        {
          img: ESGs,
          name: "ESG",
          con: "ESG是英文Environmental(环境)、Social(社会)和Governance(治理)的缩写,是一种关注企业环境、社会、治理绩效而非财务绩效的投资理念和企业评价标准。基于ESG评价,投资者可以通过观测企业ESG绩效,评估其投资行为和企业(投资对象)在促进经济可持续发展、履行社会责任等方面的贡献。",
        },
        {
          img: shuangtan,
          name: "双碳",
          con: "响应国家碳达峰、碳中和战略，致力于碳行为的数据采集和挖掘，精细化模型研究分析，提供高度可信的碳排放的咨询解决方案和认证为企业降低因碳排放权增加的违约、交易成本。",
        },
      ],
      esg: 0,
      shuangtan: 1,
      homeURL:
        "http://corapp.i.skyezone.com.cn",
    };
  },
  components: {},
  mounted () {
    _hmt.push(['_trackEvent', '首页', '进入']);
  },
  methods: {
    exchange () {
      var obj = this.esg;
      this.esg = this.shuangtan;
      this.shuangtan = obj;
    },
    //下方2个申请试用
    go (val) {
      if (val == 1) {
        window.open(this.homeURL);
      } else {
        this.$router.push("/contactUs");
      }
    },
    //首页申请试用
    click () {
      window.open(this.homeURL);
    },
    //向下
    but () {
      this.$nextTick(() => {
        var app = document.getElementById("app");
        var box = document.getElementById("box1");
        app.scrollTop = box.scrollHeight;
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import './index.less';
</style>
